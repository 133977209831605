import {defineJQueryPlugin, typeCheckConfig} from "bootstrap/js/src/util";
import EventHandler from "bootstrap/js/src/dom/event-handler";
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';
import BaseComponent from 'bootstrap/js/src/base-component';

const NAME = 'wModal'
const DATA_KEY = 'cmswm.modal'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const SELECTOR_DATA_WIDGET = '[data-widget="modal"]'
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

class WModal extends BaseComponent {

    static get NAME() {
        return NAME
    }

    constructor(element, config) {
        super(element)
        this._config = this._getConfig(config)
        this._scriptLoaded = 0
        this._isAllscriptLoaded = false
        let modalType = this._config.modalSize != 'undefined' ? ' ' + this._config.modalSize + ' ' : ''
        this._element = element
        this._uid = this._config.target ? this._config.target : this.generateUid()
        this._modal = null
        this._template = {
            dialog: '<div class="modal-dialog' + modalType + '"><div class="modal-content"></div></div>',
            header: '<div class="modal-header"><h4 class="modal-title"></h4><button type="button" class="close" data-dismiss="modal" aria-label="Cancel"><span aria-hidden="true">&times;</span></button></div>',
            body: '<div class="modal-body"></div>',
            footer: '<div class="modal-footer"></div>',
            buttonCLose: 'x',
            loader: '<div class="loader wm-modal-loader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>'
        }
        this._supportDOM = this.supportDOM()
        this.init()
    }

    getTemplate(id) {
        return this._template[id]
    }

    getDialog() {
        return SelectorEngine.findOne('.modal-dialog', this._modal_container)
    }

    getHeader() {
        return SelectorEngine.findOne('.modal-header', this._modal_container)
    }

    getBody() {
        return SelectorEngine.findOne('.modal-body', this._modal_container)
    }

    getContent() {
        return SelectorEngine.findOne('.modal-content', this._modal_container)
    }

    getFooter() {
        return SelectorEngine.findOne('.modal-footer')
    }


    setClientContent() {

    }

    setHeader(title) {
        if (!this.getHeader()) {
            this.getContent().insertAdjacentHTML('afterbegin', this.getTemplate('header'))
        }
        this.getHeader().innerHTML = title
    }

    setBody(content) {
        if (!this.getBody()) {
            this.getContent().insertAdjacentHTML('beforeend', this.getTemplate('body'))
        }

        this.getBody().innerHTML = content
    }

    setContent(content) {
        this.getContent().innerHTML = content
    }

    setFooter(footer) {
        if (!this.getFooter()) {
            this.getContent().insertAdjacentHTML('beforeend', this.getTemplate('footer'))
        }
        this.getFooter().innerHTML = footer
    }

    setFormWrapper(form) {
        let _form = document.createElement('form')
        _form.action = form.action
        _form.method = form.method
        _form.id = form.id
        _form.insertAdjacentHTML('beforeend', this.getDialog().innerHTML)
        this.getDialog().innerHTML = ""
        this.getDialog().insertAdjacentElement('beforeend', _form)
    }

    generateUid() {
        let S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (S4() + S4() + "-" + S4() + "-" + S4());
    }

    createModal() {
        this._modal_container = document.createElement('div')
        this._modal_container.id = this._uid
        this._modal_container.className = 'modal fade' + (( typeof this._config.modalClass !== 'undefined' ) ? ' ' + this._config.modalClass : '')
        this._modal_container.tabIndex = -1
        this._modal_container.innerHTML = this.getTemplate('dialog')
        this.getContent().innerHTML += this.getTemplate('header')
        this.getContent().innerHTML += this.getTemplate('body')
        this.getContent().innerHTML += this.getTemplate('footer')

        document.body.appendChild(this._modal_container)
    }

    getContents() {
        let url = this._config.url

        if (url !== undefined) {
            if (url) {
                let headers = new Headers({
                    'X-Requested-With': 'XMLHttpRequest'
                })

                fetch(url, {
                    headers : headers
                })
                    .then(response => response.text())
                    .then(data => {
                        let output = ''
                        let html = this.toHtml(data)
                        this._assets = {
                            'js' : [],
                            'css' : []
                        }

                        let children = [...html.body.children]
                        let head = [...html.head.children]

                        for ( let i = 0; i <= head.length; i++) {
                            if (head[i] instanceof HTMLLinkElement){
                                this._assets.css.push(head[i])
                            }
                        }

                        for (let i = 0; i <= children.length; i++ ){
                            if ( children[i] instanceof HTMLScriptElement ) {
                                this._assets.js.push(children[i])
                                children.splice(i,1)
                                i--
                            }
                        }

                        this._assets.css.forEach(this.injectStyles, this)
                        this._assets.js.forEach(this.injectScripts, this)

                        children.forEach((el, i)=>{
                            output += el.outerHTML
                        })

                        let options = {
                            body : output
                        }
                        this.reset()
                        this.setOptions(options)

                    })

            }
        } else if (this._config.content) {

            let _el = document.querySelector('#' + this._config.content)

            let options = {
                header: SelectorEngine.findOne('header', _el).innerHTML,
                body: SelectorEngine.findOne('article', _el).innerHTML,
                footer: SelectorEngine.findOne('footer', _el).innerHTML
            }

            if (_el.dataset.submit !== undefined) {
                let _form = SelectorEngine.findOne('form', _el)
                options.form = {
                    action: _el.dataset.submit,
                    id: _form.id,
                    method: _form.getAttribute('method')
                }
            }

            this.reset()
            this.setOptions(options)
        }
    }

    existsAssets(asset, type = 'js') {
        if (type === 'js') {
            return document.querySelectorAll(`script[src="${asset}"]`).length > 0
        } else if (type === 'css') {
            return document.querySelectorAll(`link[href="${asset}"]`).length > 0
        } else {
            throw "None acceptable asset type"
        }
    }

    supportDOM() {
        if (!window.DOMParser) return false
        let parser = new DOMParser()
        try {
            parser.parseFromString('x', 'text/html')
        } catch (er) {
            return false
        }
        return true
    }

    toHtml(str) {
        if ( this._supportDOM ) {
            let parser = new DOMParser()
            let doc = parser.parseFromString(str, 'text/html')
            return doc
        }

        let dom = document.createElement('div')
        dom.innerText = str
        return dom
    }

    init() {
        this.createModal()
    }

    injectScripts(el, i, a) {
        let script = document.createElement('script')
        let src = el.getAttribute("src")
        script.async = false
        if ( src !== null && this.existsAssets( src ) ) {
            this._scriptLoaded++
            this.afterScriptsLoadedCallback()
            return
        }
        if ( typeof el.attributes.src !== 'undefined' &&  el.attributes.src === '') {
            script.appendChild(document.createTextNode(el.text))
        }
        if ( el.hasAttributes() ) {
            for( let i = el.attributes.length - 1 ; i >= 0;   i-- ) {
                script[el.attributes[i].name] = el.attributes[i].value
            }
        }
        if ( el.innerHTML !== '' ) {
            script.text = el.innerHTML
        }
        script.onload = () => {
            this._scriptLoaded++
            if ( this._assets.js.length - 1 == this._scriptLoaded ) {
                this._isAllscriptLoaded = true
                this.afterScriptsLoadedCallback()
            }
        }
        document.head.append(script)
    }

    afterScriptsLoadedCallback() {
        if (typeof this._config.onloadCallback !== 'undefined') {
            console.log('callback')
            let fn = window[this._config.onloadCallback]
            if (typeof fn === "function") fn()
        }

    }

    injectStyles(el, i, a) {
        let css = document.createElement('link')
        if ( this.existsAssets(el.getAttribute('href'), 'css' ) ) {
            return
        }
        if ( el.getAttribute('href') === '') {
            css.appendChild(document.createTextNode(el.text))
        }
        if ( el.hasAttributes() ) {
            for ( let i = el.attributes.length - 1; i >= 0; i-- ) {
                css[el.attributes[i].name] = el.attributes[i].value
            }
        }

        document.head.append(css)

    }

    show() {
        this.setOptions({})
        this.setContent(this.getTemplate('loader'))
        this.getContents()
        this._modal_bs = new bootstrap.Modal(this._modal_container)
        this._modal_bs.show()

    }

    setOptions(options) {

        if (typeof options.header !== "undefined")
            this.setHeader(options.header)

        if (typeof options.body !== "undefined")
            this.setBody(options.body)

        if (typeof options.content !== "undefined")
            this.setContent(options.content)

        if (typeof options.footer != "undefined")
            this.setFooter(options.footer)

        if (typeof options.form != "undefined") {
            this.setFormWrapper(options.form)
        }

    }

    reset() {
        this.getContent().replaceChildren()
    }

    destroy() {
    }

    _getConfig(config) {
        config = {
            ...this._element.dataset,
            ...(typeof config === 'object' ? config : {})
        }
        return config
    }

    static jQueryInterface(config) {
        return this.each(() => {
            const data = WModal.getOrCreateInstance(this, config)
            if (typeof config === "string") {
                data[config](this)
            }
        })
    }


}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_WIDGET, e => {
    e.preventDefault();
    const target = e.target.closest(SELECTOR_DATA_WIDGET);

    const data = WModal.getOrCreateInstance(target);

    data.show()
})

defineJQueryPlugin(WModal)

export default WModal