class FileManager {
    constructor()
    {
        this.initialize()
    }

    loadInfo() {
        let hostname = window.location.origin
        let url = hostname + '/media/get-file-info'
        fetch(url)
            .then(response => response.json())
            .then(data => {

            })
    }

    initialize()
    {
       [...document.getElementsByClassName('mediamanager--fileitem')].forEach(
           (el, i, arr) => {
               el.addEventListener('click', function() {
                   this.classList.toggle('mediamanager--fileitem-selected')

               })
           }
       )
    }

    events()
    {

    }


}

export default FileManager
