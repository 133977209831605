import "../scss/wmcms.scss";

// AdminKit (required)
//import "./modules/bootstrap";
import "./modules/sidebar";
import "./modules/theme";
import "./modules/feather";
import "./modules/wmodal";
import Filemanager from "./modules/filemanager";
let filemanager = new Filemanager()
